import {Webview} from "./NativeAPI";

class LinkInterceptor{
    public static initialize(){
        let interceptClickEvent = (e)=>{
            let href;
            let target = e.target || e.srcElement;
            if (target.tagName === 'A') {
                href = target.getAttribute('href');
                if(href.charAt(0)==="#")
                    return;
                if(href.includes("tel:")){
                    console.info("have tel");
                }else{
                    e.preventDefault();
                    Webview.present({
                        url:href,
                        title:"",
                        parseCallback:false,
                        onFail:()=>{},
                    });
                }
            }
        };
        if (document.addEventListener) {
            document.addEventListener('click', interceptClickEvent);
        } else if ((document as any).attachEvent) {
            (document as any).attachEvent('onclick', interceptClickEvent);
        }
    }

}

export { LinkInterceptor };