import * as React from 'react';
import {LinkInterceptor} from "utils/LinkInterceptor";
import "core-js/stable";
import "regenerator-runtime/runtime";
import 'url-search-params-polyfill';
import 'unfetch/polyfill';
import 'promise-polyfill/src/polyfill';

const render = async () =>{
    await import('network/ClientStore');
    (await import('./indexCommon')).indexReport();
};

window.onload = function () {
    LinkInterceptor.initialize();
    render();
};

