module.exports = {
  "dev":false,
  "firebase_config":{
    "apiKey": "AIzaSyB18vE0bAXOJ0UauEGGHL3DVquI4WRjRQM",
    "authDomain": "pilseta.jelgava.lv",
    "databaseURL": "https://jelgavas-pilseta-prod.firebaseio.com",
    "projectId": "jelgavas-pilseta-prod",
    "storageBucket": "jelgavas-pilseta-prod.appspot.com",
    "messagingSenderId": "871230510426"
  },
  "webPushkeyPair": "BKUbhiELbW1M3Iy2p9HMxFoI8wVJS4lcd2GtKpLxo2PPa_oWEcfKHYdYb7xFiRSqRW7qZ9GsFiEwnDQCFCN2p5g",
  "graphQlApi":"https://mcity.mobilly.lv/graphql",
  "etelpaAuthUrl":"https://mcity.mobilly.lv/etelpa",
  "etelpaSessionTokenUrl":"https://mcity.mobilly.lv/etelpa/session-token",
  "fileUploadsUrl": "https://mcity.mobilly.lv/uploads",
  "googleWebClientId": "871230510426-gt07m8ku171d1l90pr23lo0neq07i2mv.apps.googleusercontent.com"
};