const entryPoint = require('entryPoint');

export class AssertCordova {
    static assert():boolean{
        // @ts-ignore
        return typeof cordova === 'undefined';
    }
    static assertAndroid():boolean{
        // @ts-ignore
        return cordova.platformId!=="android";
    }
    static assertIOS():boolean{
        // @ts-ignore
        return cordova.platformId!=="ios";
    }
}

export interface IConnection {
    offline: boolean;
}

interface IWebViewCallback{
    event:string;
    data:any;
}
interface IWebViewParams{
    url:string;
    title:string;
    onCallback?:(data:IWebViewCallback)=>void;
    parseCallback?:boolean;
    onFail?:Function;
    etelpa?:string;
}

export class WebView {
    static CLOSED:string = "webViewClosed";
    static CALLBACK:string = "CALLBACK";
    static WEBVIEW_INIT_FAIL:string = "WEBVIEW_INIT_FAIL";
}

export class Webview {

    static present(params:IWebViewParams ){
        let childWindow:any = false,
            postMessage:boolean = false,
            timer:any =false;

        const postMessageCallback = (event:any)=>{
            // Avoid devtools breaking this callback
            //
            console.info("postMessageCallback",event);

            if((event.data.source && event.data.source === '@devtools-page') ||
                event.data.source === 'apollo-devtools-backend' ||
                event.origin.includes("://localhost"))
                return;
            console.info("postMessageCallback",event);
            postMessage = true;
            if(childWindow && event.data.length>0)
                childWindow.close();
            if(params.onCallback){
                if(params.parseCallback){
                    params.onCallback({
                        event:WebView.CALLBACK,
                        data:JSON.parse(event.data),
                    });
                }else{
                    params.onCallback({
                        event:WebView.CALLBACK,
                        data:event.data,
                    });
                }

                window.removeEventListener("message",postMessageCallback,false);
            }


        };


        // No cordova open regular window
        if(AssertCordova.assert()){
            childWindow = window.open(params.url, 'webWiewOverlay', `height=${window.innerHeight}, width=${window.innerWidth}`);


            const timeout=()=>{
                if(childWindow.closed){
                    console.info("window",childWindow);
                    fetch(params.etelpa,{
                        method: "GET",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Cache': 'no-cache'
                        },
                        credentials: 'include'
                    })
                        .then(async data => {
                            const json = await data.json();
                            if(json){
                                params.onCallback({
                                    event:WebView.CALLBACK,
                                    data:json.accessToken,
                                });
                            }
                        })
                        .catch(error=> {
                            console.info("error",error)
                        });
                    return;
                }
                setTimeout(timeout, 100);
            };

            if(params.etelpa)
                setTimeout(timeout, 100);
            // window.addEventListener("message", postMessageCallback, false);
            // Use native mobile webview
        }else{


            let parseQuery = (qstr:string):any => {
                let query = {};
                let a = (qstr[0] === '?' ? qstr.substr(1) : qstr).split('&');
                for (let i = 0; i < a.length; i++) {
                    let b = a[i].split('=');
                    query[decodeURIComponent(b[0])] = decodeURIComponent(b[1] || '');
                }
                return query;
            };

            // @ts-ignore
            window.webview.present(params.url, "mcity", params.title,
                (data:any) => {
                    console.log("NativeApi | webview.present() | OKcallback | RAW data:", data);
                    let parsedQuery = data !== WebView.CLOSED ? parseQuery(data) : false;
                    console.log("NativeApi | webview.present() | OKcallback | parsedQuery:", parsedQuery);
                    if(params.onCallback){
                        params.onCallback({
                            event:data === WebView.CLOSED ? WebView.CLOSED : WebView.CALLBACK,
                            data:parsedQuery,
                        });
                    }
                }, (command:any) => {
                    console.log("NativeApi | webview.present() | FAILcallback | command:", command);
                    if(params.onFail){
                        params.onFail({
                            event:WebView.WEBVIEW_INIT_FAIL,
                            data:false,
                        });
                    }
                });
        }
    }


}

export class SystemInfo {
    static watchConnection(callback: (data: IConnection) => void) {
        document.addEventListener(
            'deviceready',
            () => {
                document.addEventListener(
                    'offline',
                    () => {
                        callback({ offline: true });
                    },
                    false,
                );
                document.addEventListener(
                    'online',
                    () => {
                        callback({ offline: false });
                    },
                    false,
                );
            },
            false,
        );
    }

    static checkConnection() {
        if (AssertCordova.assert()) return false;
        // @ts-ignore
        const networkState = navigator.connection.type;
        // @ts-ignore
        return networkState !== Connection.NONE;
    }

}

let watchID:any;
export class Location{
    static get(_successCallback: PositionCallback, _errorCallback?: PositionErrorCallback){
        navigator.geolocation.getCurrentPosition(_successCallback, _errorCallback,{ maximumAge: 1000, timeout: 10000, enableHighAccuracy: true });
    }


    static watchPosition(_successCallback: PositionCallback, _errorCallback?: PositionErrorCallback){
        watchID = navigator.geolocation.watchPosition(_successCallback, _errorCallback, { enableHighAccuracy: true });
    }

    static clearWatch(){
        navigator.geolocation.clearWatch(watchID);
    }

}

export class Camera{
    /**
     * Initializes camera - asks for permission if needed and starts camera
     * @param _return - Returns result or error
     */

    static backEvent(_e){
        console.info(_e);
        document.removeEventListener("backbutton", Camera.backEvent,false);
        Camera.disablePreview();
    }

    static enablePreview(){
        // if(AssertCordova.assert()) return;
        document.body.classList.add('qr-code-overlay');
        document.addEventListener("backbutton", Camera.backEvent , false);
    }

    static disablePreview(){
        document.removeEventListener("backbutton", Camera.backEvent,false);
        document.body.classList.remove('qr-code-overlay');
        if(AssertCordova.assert()) return;
        QRScanner.hide(function(status){
            console.log("QRScanner.hide",status);
        });
        QRScanner.cancelScan(function(status){
            console.log("QRScanner.cancelScan",status);
        });
        QRScanner.destroy(function(status){
            console.log("QRScanner.destroy",status);
        });
    }

    static init(_return:(_error: QRScannerError, _data:string)=>void){
        if(AssertCordova.assert()) return;
        console.log("QRScanner.prepare");
        QRScanner.prepare(
            (_err:QRScannerError, _status:QRScannerStatus)=>{
                console.log("prepare", _err, _status);
                if(_err){
                    console.warn(   'NativeAPI | Camera | ERROR:',_err);
                    _return(_err,null);
                } else {
                    console.log(    'NativeAPI | Camera | Is initialized:', _status);
                    if(_status.authorized && _status.prepared){
                        QRScanner.scan(
                            function (_error:QRScannerError,_data:string):void{
                                if(_error)  console.warn(   'NativeAPI | Camera | Scan ERROR:',_error);
                                else        console.info(   'NativeAPI | Camera | Got data:', _data);
                                _return(_error,_data);
                            });
                        QRScanner.show(function(_status:QRScannerStatus){
                            console.log('NativeAPI | Camera | show:', _status);
                        });
                    }
                }
            }
        );
    }

    static destroy(){
        if(AssertCordova.assert()) return;
        QRScanner.destroy((status:any)=>{
            console.log("QRScanner status", status);
        });
    }
}

export interface IIsLocationEnabled{
    isEnabled?:boolean;
}
export interface IIsLocationAuthorized{
    isAuthorized?:boolean;
}

function assertPermission(_success:()=>void,_fail:(_reason:any)=>void,_permission: any){
    if(AssertCordova.assert() || AssertCordova.assertAndroid()) return;
    console.info("Continue with assert permission !!!");

    cordova.plugins.diagnostic.requestRuntimePermission(function(status:any){
        console.info("GOT RESPONSE ",status);
        switch(status){
            case cordova.plugins.diagnostic.permissionStatus.GRANTED:
                _success();
                break;
            case cordova.plugins.diagnostic.permissionStatus.NOT_REQUESTED:
            case cordova.plugins.diagnostic.permissionStatus.DENIED_ONCE:
            case cordova.plugins.diagnostic.permissionStatus.DENIED_ALWAYS:
            default:
                _fail(status);
                break;
        }
    }, function(error){
        _fail(error);
    }, _permission);
}

export class GoogleAuth {
    static login = (success, fail) => {
        window.plugins.googleplus.login({
            webClientId: entryPoint.googleWebClientId
        }, success, fail);
    }
}

export class Notifications {
    static getToken = (callback) => {
        try {
            window.FirebasePlugin.getToken((token)=>{
                console.log("Got token: " + token);
                callback(token);
            }, (error) => {
                callback('');
                console.error("Failed to get token");
                console.error(error);
            });
        } catch (error) {
            callback('');
            console.error("Failed to get token");
            console.error(error);
        }
    };

    static onTokenRefresh = (callback) => {
        window.FirebasePlugin.onTokenRefresh(newToken => {
            callback(newToken)
        }, error => {
            console.error(error);
        });
    };

    static checkPermission = (requested, callback) => {
        window.FirebasePlugin.hasPermission((hasPermission) => {
            if(hasPermission){
                console.log("Remote notifications permission granted");
                // Granted
                Notifications.getToken(callback);
            }else if(!requested){
                // Request permission
                console.log("Requesting remote notifications permission");
                window.FirebasePlugin.grantPermission(()=>Notifications.checkPermission(true, callback));
            }else{
                // Denied
                console.error("Notifications won't be shown as permission is denied");
            }
        });
    };

    static onMessageReceived = (callback) => {
        window.FirebasePlugin.onMessageReceived((message) => {
            try{
                callback(message);
            }catch(e){
                console.error("Exception in onMessageReceived callback: " + e.message);
            }
        }, (error) => {
            console.error("Failed receiving FirebasePlugin message: ", error);
        });
    }

}

export class Permissions{
    static assertNotificationPermission( _success:()=>void,_fail:()=>void ){
        if(AssertCordova.assert()) return;

        cordova.plugins.notification.local.registerPermission(_result => {
            if(_result) _success();
            else _fail();
        });
    }

    static assertSmsPermission(_success:()=>void,_fail:(_reason:any)=>void){
        assertPermission(_success,_fail,"SEND_SMS");
    }

    static assertCameraPermission(_success:()=>void,_fail:(_reason:any)=>void){
        assertPermission(_success,_fail,"CAMERA");
    }

    static assertGpsPermission(_success:()=>void,_fail:(_reason:any)=>void){
        console.info("GPS assertGpsPermission");
        assertPermission(_success,_fail,"ACCESS_FINE_LOCATION");
    }

    static isLocationEnabled(callback:(response:IIsLocationEnabled)=>void){
        if(AssertCordova.assert()) return callback({isEnabled:true});
        cordova.plugins.diagnostic.isLocationEnabled((enabled)=>{
            console.log("Location setting is " + (enabled ? "enabled" : "disabled"));
            callback({isEnabled:enabled});
        }, function(error){
            console.error("The following error occurred: "+error);
            callback({isEnabled:false});
        });
    }

    static isLocationAuthorized(callback:(response:IIsLocationAuthorized)=>void){
        if(AssertCordova.assert()) return;
        cordova.plugins.diagnostic.isLocationAuthorized((authorized)=>{
            console.log("Location is " + (authorized ? "authorized" : "unauthorized"));
            callback({isAuthorized:authorized});
        }, function(error){
            console.error("The following error occurred: "+error);
            callback({isAuthorized:false});
        });
    }

}

export class NativeStorage {

    static resolveLocalFileSystemURL(path: string): Promise<Entry> {
        return new Promise<Entry>((resolve, reject) => window.resolveLocalFileSystemURL(path, resolve, reject));
    }

    private static getCacheDirectory(): Promise<DirectoryEntry> {
        switch (device.platform) {
            case "Android":
            case "iOS": {
                return <Promise<DirectoryEntry>>this.resolveLocalFileSystemURL(cordova.file.dataDirectory);
            }
            default: {
                return Promise.reject("Platform is not supported");
            }
        }
    }

    private static writeFile(entry: FileEntry, blob: Blob): Promise<void> {
        const getWriter = new Promise((resolve, reject) => entry.createWriter(resolve, reject));
        const write = (writer: FileWriter) => new Promise<void>((resolve, reject) => {
            writer.onwrite = (_e: ProgressEvent) => resolve();
            writer.onabort = reject;
            writer.onerror = reject;
            writer.write(blob);
        });
        return getWriter.then(write);
    }

    private static saveFileToDirectory(dir: DirectoryEntry, file: File): Promise<void> {
        const createFile: Promise<FileEntry> = new Promise((resolve, reject) => dir.getFile(file.name, {create: true}, resolve, reject));
        return createFile.then((entry: FileEntry) => this.writeFile(entry, file));
    }

    private static getDirectoryFileEntry(dir: DirectoryEntry, fileName: string): Promise<FileEntry> {
        return new Promise<FileEntry>((resolve, reject) => dir.getFile(fileName, {}, resolve, reject));
    }

    static saveFileToCache(file: File): Promise<void> {
        return this.getCacheDirectory().then((dir: DirectoryEntry) => this.saveFileToDirectory(dir, file));
    }

    private static getCachedFileEntry(fileName: string): Promise<FileEntry> {
        return this.getCacheDirectory().then((dir: DirectoryEntry) => this.getDirectoryFileEntry(dir, fileName));
    }

    static clearCachedFile(fileName: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            NativeStorage.getCachedFileEntry(fileName)
                .then((entry: FileEntry) => {
                    entry.remove(resolve, reject);
                })
                .catch(reject);
        });
    }

    static readFileEntry(fileEntry: FileEntry, fileName: string, extension: string): Promise<Blob> {
        const type = `image/${extension}`;
        return new Promise<File>((resolve, reject) => fileEntry.file(resolve, reject))
            .then((file: File) => {
                return new Promise<Blob>((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = (_e: ProgressEvent) => {
                        resolve(new Blob([new Uint8Array(<ArrayBufferLike>reader.result)], { type }));
                    };
                    reader.onabort = reject;
                    reader.onerror = reject;
                    reader.readAsArrayBuffer(file);
                });
            })
            .then((blob: Blob) => {
                Object.defineProperty(blob, 'name', {
                    value: fileName,
                    writable: false
                });
                return blob;
            });
    }

    static getFileFromCache(fileName: string): Promise<Blob> {
        const extension = fileName.split('.')[1];
        return NativeStorage.getCachedFileEntry(fileName)
            .then( (fileEntry: FileEntry) => {
                return NativeStorage.readFileEntry(fileEntry, fileName, extension);
            });
    }
}
